<template>
    <v-container fluid class="d-flex fill-height text-center mt-0 mb-0 mx-0 pt-10 pb-15 backcolor">
        <v-row dense no-gutters justify="center" align="start" class="text-center">
            <v-col cols="12">
                <span class="blue--text text-md-h4">
                    Aquí encontrarás lo mejor de Baja California
                </span>
            </v-col>
            <v-col cols="12">
                <h1 class="text-md-h4 text-uppercase">
                    Trabajando Juntos
                </h1>
            </v-col>
            <v-col cols="12">
                <p class="text-md-h5 grey--text">
                    Médicos experimentados y certificados
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <!--<div class="white&#45;&#45;text mx-2 mt-5">-->
                    <v-row align="start" justify="space-around" class="text-left">
                        <v-col v-for="(item, index) in cardItems" :key="index" cols="12" xs="12" sm="6" md="3" >
                            <a v-bind:href="item.linkextra" style="text-decoration: none !important;" >
                                <v-card style="border-radius: 30px !important;" elevation="5" rounded="10">
                                    <v-img :src="item.url" height="250"></v-img>
                                    <v-card-subtitle>
                                        <v-icon color="brown" style="font-size: 50px !important;">{{ item.icon }}</v-icon>
                                        <span class="grey--text text--darken-2" style="margin-left:5px;font-size: 24px !important;">{{ item.title }}</span>
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-row align="center" class="mx-0">
                                            <v-col cols="12">
                                                <div style="font-size: 18px !important;" class="font-weight-light grey--text text--darken-1">{{ item.detail }}</div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions style="margin-left: 11px;">
                                        <v-btn transparent text color="blue" plain :to="item.linkTo"><span>Ver más</span>
                                            <v-icon small>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </a>
                        </v-col>
                    </v-row>
                <!--</div>-->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "TheBestTijuanaComponent",
    data: () => ({
        cardItems: [{
                icon: 'mdi-doctor',
                url: require('@/assets/images/doctor-final.jpg'),
                title: "Médicos",
                detail: "Ponte en contacto con los mejores médicos, toda la información que necesitas en un solo lugar.",
                linkTo: "/medical-services",
                linkextra: "#/medical-services",
                flex: 3,
            },
            {
                icon: 'mdi-hospital-building',
                url: require('@/assets/images/hospital-clinica.jpg'),
                title: "Hospitales y Clínicas",
                detail: "En BHC proporcionamos datos e información para ayudarlo a tener la mejor decisión de hospital o clínica.",
                linkTo: "/hospitals-clinics",
                linkextra: "#/hospitals-clinics",
                flex: 3,
            },
            {
                icon: 'mdi-silverware-fork-knife',
                url: require('@/assets/images/work-together-restaurants.jpg'),
                title: "Restaurantes y Entretenimientos",
                detail: "Disfrute de la cocina más revolucionaria de México, desde los clásicos tacos hasta la excelente gastronomía con estrellas Michelin.",
                linkTo: "/restaurants-leisures",
                linkextra: "#/restaurants-leisures",
                flex: 3,
            },
            {
                icon: 'mdi-bed',
                url: require('@/assets/images/work-together-hotels.jpg'),
                title: "Hoteles",
                detail: "Los Hoteles han reinventado sus servicios ofreciendo amplias opciones para recibir pacientes médicos.",
                linkTo: "/hotels",
                linkextra: "#/hotels",
                flex: 3,
            },
        ],
    }),
};
</script>

<style scoped>
.backcolor {
    background-color: rgba(229, 223, 240, 2);
}

.v-btn {
    text-transform: none !important;
}
</style>
